.autocomplete-field {
  position: relative;
  .suggestions {
    box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, .2), -0.1rem -0.1rem 0.3rem rgba(0, 0, 0, .2);
    border-radius: .1rem;
    position: absolute;
    z-index: 999;
    width: 100%;
    background-color: #fff;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    a {
      padding: .5rem 1rem;
      display: block;
      text-decoration: none;
      text-shadow: none;
      font-weight: 400;
      line-height: 1.25;
      color: #55595c;
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      &:hover, &:focus {
        outline: none;
        background-color: rgba(#000, 0.1);
      }
    }
    li.disabled a {
      color: rgba(#000, .36);
      cursor: default;
      &:hover {
        background-color: #fff;
      }
    }
  }
  .input-wrapper {
    position: relative;
    input.form-control {
      padding-right: 2rem;
    }

    .input-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding-top: .3rem;
      padding-bottom: .3rem;
      font-size: 1.7rem;
      line-height: 100%;
      padding-right: .6rem;
      color: rgba(#000, .23);
      text-shadow: none;
      cursor: pointer;

      &:hover, &:active {
        color: rgba(#000, .56);
      }
    }
  }
}
$bounce-size: 1rem;
.spinner {
  text-align: center;
}

.spinner > div {
  width: $bounce-size;
  height: $bounce-size;
  background-color: rgba(#000, .23);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
