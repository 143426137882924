$left-side-size: 4;
.bottom-single-overlay .details-slider {
  @extend .col-xs-12;
  position: absolute;
  padding: 0;
  left: 0;
  top: 0;
}
.bottom-split .details-slider {
  @extend .left-side;
}
.details-slider {
  background-color: $light-bg;
  -webkit-transition: left .5s; /* Safari */
  transition: left .5s;
  z-index: 333;
  box-shadow: $shadow;
  overflow: hidden;

  .close-button {
    @extend .close;
    float: none;
    text-align: right;
    padding: 0 .3rem;
    opacity: .3;
  }
  .close-button:hover {
    opacity: .5;
  }

  @include media-breakpoint-up(xs) {
    &.hidden {
      left: -110%;
    }
  }
  @include media-breakpoint-up(md) {
    &.hidden {
      left: (100% / 12 * 6 * -1) - 10;
    }
  }
  @include media-breakpoint-up(lg) {
    &.hidden {
      left: (100% / 12 * 5 * -1) - 10;
    }
  }
  @include media-breakpoint-up(xl) {
    &.hidden {
      left: (100% / 12 * 4 * -1) - 10;
    }
  }
}
