.welcome {
  @extend .text-xs-center;
  @extend .text-shadow;
  @extend .centered;
  max-width: 1200px;
  padding-top: 5%;

  // Register form alignment
  .register-container {
    padding-left: 2.25rem;
    padding-top: .4rem;
  }
}
/*
.welcome, .login {
  @extend .text-xs-center;
  @extend .text-shadow;

  .errors {
    font-size: 1rem;
    font-weight: normal;
    text-shadow: none;
  }

  // Login and register fonts
  .login-form {
    max-width: 20rem;


    .form {
      margin-bottom: 2rem;

      input {
        max-width: 20rem;
        margin: 0 auto;
      }

      .form-control-feedback {
        text-align: left;
      }
    }
  }
}
*/

.social {
  margin-bottom: 2rem;
  margin-top: 0.7rem;

  img {
    max-height: 2.7rem;
  }
}
