.row.bottom-split.add-route-form {
  top: 4rem;
}
.add-route {
  $pad: 1rem;
  .header {
    padding: $pad $pad 0 $pad;

    h1 {
      font-size: 1.7rem;
      small {
        float: right;
      }
    }
  }

  .short-form,
  .carousel-item {
    padding: $pad;

    label .form-text {
      margin: 0;
    }
  }

  .details-left {
    @extend .col-xs-4;
  }
  .details-right {
    @extend .col-xs-8;
  }

  .duration-hours {
    padding-right: 7px;
  }
  .duration-minutes {
    padding-left: 8px;
  }

  span.required {
    color: $highlighted-color;
  }

  #otherTransport {
    margin-top: 1rem;
  }

  .buttons-row {
    @include clearfix;
  }
}

.add-route-mobile {
  @extend .col-xs-12, .col-sm-8, .offset-sm-2, .col-md-6, .offset-md-3;
  margin-top: 1rem;
}
