//
// Base styles
//

.card {
  position: relative;
  display: block;
  margin-bottom: $card-spacer-y;
  background-color: $card-bg;
  // border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
  border: $card-border-width solid $card-border-color;
}

.card-block {
  @include clearfix;
  padding: $card-spacer-x;
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -($card-spacer-y / 2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

// .card-actions {
//   padding: $card-spacer-y $card-spacer-x;

//   .card-link + .card-link {
//     margin-left: $card-spacer-x;
//   }
// }

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

.card {
  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}


//
// Optional textual caps
//

.card-header {
  @include clearfix;
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-border-radius-inner $card-border-radius-inner 0 0);
  }
}

.card-footer {
  @include clearfix;
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-border-radius-inner $card-border-radius-inner);
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: -($card-spacer-x / 2);
  margin-bottom: -$card-spacer-y;
  margin-left: -($card-spacer-x / 2);
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -($card-spacer-x / 2);
  margin-left: -($card-spacer-x / 2);
}


//
// Background variations
//

.card-primary {
  @include card-variant($brand-primary, $brand-primary);
}
.card-success {
  @include card-variant($brand-success, $brand-success);
}
.card-info {
  @include card-variant($brand-info, $brand-info);
}
.card-warning {
  @include card-variant($brand-warning, $brand-warning);
}
.card-danger {
  @include card-variant($brand-danger, $brand-danger);
}

// Remove all backgrounds
.card-outline-primary {
  @include card-outline-variant($btn-primary-bg);
}
.card-outline-secondary {
  @include card-outline-variant($btn-secondary-border);
}
.card-outline-info {
  @include card-outline-variant($btn-info-bg);
}
.card-outline-success {
  @include card-outline-variant($btn-success-bg);
}
.card-outline-warning {
  @include card-outline-variant($btn-warning-bg);
}
.card-outline-danger {
  @include card-outline-variant($btn-danger-bg);
}

//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
  @include card-inverse;
}

//
// Blockquote
//

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0;
}

// Card image
.card-img {
  // margin: -1.325rem;
  @include border-radius($card-border-radius-inner);
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}



// Card image caps
.card-img-top {
  @include border-top-radius($card-border-radius-inner);
}
.card-img-bottom {
  @include border-bottom-radius($card-border-radius-inner);
}


// Card set
//
// Heads up! We do some funky style resetting here for margins across our two
// variations (one flex, one table). Individual cards have margin-bottom by
// default, but they're ignored due to table styles. For a consistent design,
// we've done the same to the flex variation.
//
// Those changes are noted by `// Margin balancing`.

@if $enable-flex {
  @include media-breakpoint-up(sm) {
    .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -$card-deck-margin;
      margin-bottom: $card-spacer-y; // Margin balancing
      margin-left: -$card-deck-margin;

      .card {
        flex: 1 0 0;
        margin-right: $card-deck-margin;
        margin-bottom: 0; // Margin balancing
        margin-left: $card-deck-margin;
      }
    }
  }
} @else {
  @include media-breakpoint-up(sm) {
    $space-between-cards: (2 * $card-deck-margin);
    .card-deck {
      display: table;
      width: 100%;
      margin-bottom: $card-spacer-y; // Margin balancing
      table-layout: fixed;
      border-spacing: $space-between-cards 0;

      .card {
        display: table-cell;
        margin-bottom: 0; // Margin balancing
        vertical-align: top;
      }
    }
    .card-deck-wrapper {
      margin-right: (-$space-between-cards);
      margin-left: (-$space-between-cards);
    }
  }
}

//
// Card groups
//

@include media-breakpoint-up(sm) {
  .card-group {
    @if $enable-flex {
      display: flex;
      flex-flow: row wrap;
    } @else {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .card {
      @if $enable-flex {
        flex: 1 0 0;
      } @else {
        display: table-cell;
        vertical-align: top;
      }

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:first-child {
          @include border-right-radius(0);

          .card-img-top {
            border-top-right-radius: 0;
          }
          .card-img-bottom {
            border-bottom-right-radius: 0;
          }
        }
        &:last-child {
          @include border-left-radius(0);

          .card-img-top {
            border-top-left-radius: 0;
          }
          .card-img-bottom {
            border-bottom-left-radius: 0;
          }
        }

        &:not(:first-child):not(:last-child) {
          border-radius: 0;

          .card-img-top,
          .card-img-bottom {
            border-radius: 0;
          }
        }
      }
    }
  }
}


//
// Card
//

@include media-breakpoint-up(sm) {
  .card-columns {
    column-count: 3;
    column-gap: $card-columns-sm-up-column-gap;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let them exceed the column width
    }
  }
}
