.lead.subtext {
  line-height: 1.2;
}
.search {
  @extend .text-shadow;
  padding-bottom: 2rem;

  .form-container {
    @extend .centered;
    @extend .text-xs-center;
    max-width: 46rem;
    margin-top: 10%;

    .form-group {
      margin-bottom: .7rem;
    }
    .form-group.first {
      @include media-breakpoint-up(sm) {
        margin-right: .4rem;
      }
    }
    .form-wrapper {
      margin-bottom: 2rem;
      .input-wrapper .input-button {
        font-size: 2rem;
      }
    }

    input {
      @extend .form-control-lg;
    }
    button {
      @extend .btn-lg;
    }
  }
}

.popover {
  background: #f9d9d9;
  color: black;
}

.popover.error-popover::after {
  border-bottom-color: #f9d9d9;
}
