/* Links */
a,
a:focus,
a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: .05rem solid #fff;
}

/*
 * Base structure
 */
$images: "/assets/images";
$bg-image: "#{$images}/sea.1920x1271.jpg";
$bg-mobile: "#{$images}/sea.450x850.jpg";
$bg-mobile-land: "#{$images}/sea.850x563.jpg";
$bg-ipad: "#{$images}/sea.1024x1271.jpg";
$bg-desktop: "#{$images}/sea.1160x768.jpg";
$bg-desktop-l: "#{$images}/sea.1400x927.jpg";
$bg-desktop-xl: "#{$images}/sea.1600x1059.jpg";

$dark-bg: #1e1a16;
$light-bg: #fff;
$highlighted-bg: rgba(44,155,186,0.1);

$light-color: #fff;
$dark-color: #000;
$muted-color: #999;
$highlighted-color: rgba(249,69,114,1);
$highlighted-color-shade: rgba(249,69,114,.8);
$selected-color: rgba(44,155,186,1);
$selected-color-shade: rgba(44,155,186,.8);

$card-border: 1px solid rgba(0,0,0,0.1);

$shadow: 4px 0 4px rgba(64,63,63,.4);

html,
body {
  min-height: 100vh;
}
body {
  color: #fff;
  background-color: $dark-bg;
  text-align: left;
  background: url($bg-image);
  background-size: cover;
}

/* Mobile portrait */
@media screen and (max-width: 450px) {
  body {
    background-image: url($bg-mobile);
  }
}
/* Mobile landscape */
@media screen and (min-width: 451px) and (max-width: 850px) and (max-height: 563px) {
  body {
    background-image: url($bg-mobile-land);
  }
}

/* Ipad */
@media screen and (min-width: 451px) and (max-width: 1024px) and (min-height: 564px) {
  body {
    background-image: url($bg-ipad);
  }
}

/* Desktop */
@media screen and (min-width: 850px) and (max-width: 1160px) and (max-height: 768px) {
  body {
    background-image: url($bg-desktop);
  }
}
/* Larger Desktop */
@media screen and (min-width: 1161px) and (max-width: 1400px) {
  body {
    background-image: url($bg-desktop-l);
  }
}
/* XL Desktop */
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  body {
    background-image: url($bg-desktop-xl);
  }
}

.text-shadow {
  // Font styles
  font-size: 1.1rem;
  font-weight: 300;
  text-shadow: 0 .05rem .1rem rgba(0,0,0,.5);
}

.centered {
  margin-right: auto;
  margin-left: auto;
}

.modal-dialog {
  color: black;
}

body.noscroll {
  overflow: hidden;
}

$placeholder-color: rgba(0, 0, 0, 0.5);
.form-control::-webkit-input-placeholder { color: $placeholder-color; }
.form-control:-moz-placeholder { color: $placeholder-color; }
.form-control::-moz-placeholder { color: $placeholder-color; }
.form-control:-ms-input-placeholder { color: $placeholder-color; }

// Different colors for some pages
body.no-picture {
  background: none;
  background-color: $light-bg;

  nav, .container-fluid {
    background-color: $dark-bg;
  }
}
