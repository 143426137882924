//
// Display utilities
//

.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline {
  display: inline !important;
}
